<i18n>
ru:
  title: Успей купить!
  description: Вы можете приобрести только одно блюдо из блока за один заказ
  timerName: 'До конца акции:'
  btnText: 'Добавить в корзину за '
ua:
  title: Встигни купити!
  description: Ви можете придбати тільки одне блюдо з блоку за одне замовлення
  timerName: 'До кінця акції:'
  btnText: 'Додати до кошику за '
us:
  title: Limited offer!
  description: You can order only one item at same time from here
  timerName: 'Until the end of the promotion:'
  btnText: 'Add to cart for '
</i18n>

<template>
  <menu-discount-timers-max-one
    v-if="discountsData.length === 1"
    :discounts-data="discountsData"
  />
  <menu-discount-timers-more-than-one
    v-else-if="discountsData.length > 1"
    :discounts-data="discountsData"
  />
</template>

<script setup lang="ts">
import type { CurrentDiscount } from '~types/menuStore'

import type { GUID } from '@arora/common'

import { DateTime } from 'luxon'

const { groupId = null } = defineProps<{
  groupId?: GUID | null
}>()

const menuStore = useMenuStore()

const discountsData = ref<CurrentDiscount[]>(
  menuStore.DiscountTimers?.data?.CurrentDiscounts ?? []
)

onMounted(() => {
  menuStore.initDiscountTimers(groupId)
})

let timer: ReturnType<typeof setTimeout>

watch(
  () => menuStore.DiscountTimers?.data?.CurrentDiscounts,
  (newValue) => {
    if (!newValue) {
      discountsData.value = []
      return
    }

    discountsData.value = newValue

    clearTimeout(timer)
    const minimalTimers = newValue.map((discount) => discount.TimeEndSecTS) ?? []

    if (minimalTimers.length === 0) return

    const timeEnd = Math.min(...minimalTimers) * 1000
    const now = DateTime.utc().toMillis()

    timer = setInterval(() => menuStore.loadDiscountTimers(groupId), timeEnd - now)
  },
  { deep: true }
)
</script>

<style lang="scss">
.v-old {
  margin-right: 8px;
}

.v-discount-timer-aggregator {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

.v-price-block {
  text-align: right;

  .v-btn {
    display: flex;
  }
}
</style>
